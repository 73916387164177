import React from 'react';

const Modal = ({ modalMsg, modalChange }) => {
  const handleChange = () => {
    modalChange(false);
  };

  return (
    <div id="myModal" className="modal">
      {/* <!-- Modal content --> */}
      <div className="modal-content">
        <span id="close" onClick={handleChange}>
          &times;
        </span>
        <p id="modal-message">{modalMsg}</p>
      </div>
    </div>
  );
};

export default Modal;
