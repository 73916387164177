import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* <!--copyright--> */}
      <span className="copyright">GR WebDev - 2021</span>
      {/* <!--footer-social-links----> */}
      <div className="footer-social">
        {/* <!--facebook--> */}
        <a
          className="foot-social"
          href="https://www.linkedin.com/in/goncalosribeiro/"
          target="blank"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        {/* <!--twitter--> */}
        <a
          className="foot-social"
          href="https://github.com/goncalosribeiro"
          target="blank"
        >
          <i className="fab fa-github"></i>
        </a>
        <p className="foot-mail">
          <span className="copyright">Email: </span>
          <a href="mailto:mail@goncaloribeiro.dev">mail@goncaloribeiro.dev</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
