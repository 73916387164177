import { Fragment } from 'react';
import './Form.css';

function Form({ name, type, onChange, value, error, palceholder, classType }) {
  const errorHandle = (name) => {
    return error.map((err, i) => {
      if (err.param === name) {
        return (
          <li className="form__input_error_li" key={i}>
            {err.msg}
          </li>
        );
      }
    });
  };

  return (
    <Fragment>
      <input
        className={`${classType}${
          error.length > 0 ? ' form_error_border' : ''
        }`}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={palceholder}
      />
      {error.length > 0 && error.find((err) => err.param === name) && (
        <ul className="form__input_error">{errorHandle(name)}</ul>
      )}
    </Fragment>
  );
}

export default Form;
