import { useEffect, useState } from 'react';
import AOS from 'aos';
import axios from 'axios';
import './App.css';

import logo from './images/logo.png';
import face from './images/face.webp';
import facex from './images/facex.webp';
import Form from './components/Form';
import CookieContainer from './components/cookieContainer';
import Modal from './components/Modal';
import Loader from './components/Loader';
import Footer from './components/Footer';
import PortfolioSlider from './components/PortfolioSlider';

function App() {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    message: '',
    buttonText: 'Send',
    loading: false,
    modal: false,
    modalMsg: '',
    error: [],
    cookieDecided: false,
  });

  axios.defaults.withCredentials = true;

  const {
    fullName,
    email,
    message,
    buttonText,
    loading,
    modal,
    modalMsg,
    error,
    cookieDecided,
  } = values;

  useEffect(() => {
    AOS.init();
  }, []);

  const handleChange = (event) => {
    // console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      buttonText: 'Send',
    });
  };

  const clickSubmit = (event) => {
    console.log(process.env.REACT_APP_AXIOS_URL);
    event.preventDefault();
    setValues({ ...values, buttonText: 'Sending...  ', loading: true });
    axios
      .post(process.env.REACT_APP_AXIOS_URL, {
        fullName,
        email,
        message,
      })
      .then((response) => {
        setValues({
          ...values,
          fullName: '',
          email: '',
          message: '',
          loading: false,
          modal: true,
          buttonText: 'Send',
          modalMsg: response.data.msg,
          error: [],
        });
      })
      .catch((error) => {
        setValues({
          ...values,
          buttonText: 'Send',
          loading: false,
          modal: false,
          error: error.response.data.errors,
        });
      });
  };

  const modalChange = (value) => {
    setValues({ ...values, modal: value });
  };

  return (
    <div className="App">
      {modal && <Modal modalMsg={modalMsg} modalChange={modalChange} />}
      {loading && <Loader />}
      {/* <!--navigation-bar --> */}
      <nav className="navigation">
        {/* <!--logo--> */}
        <div className="logo-container">
          <a href="#main" className="logo link">
            <img src={logo} alt="logo" />
          </a>
          <a href="#main" className="logo link">
            <h1 className="logo-title">
              GONÇALO<span>RIBEIRO</span>
            </h1>
          </a>
        </div>
        {/* <!--menu-btn-----> */}
        <input type="checkbox" className="menu-btn" id="menu-btn" />
        <label htmlFor="menu-btn" className="menu-icon">
          <span className="nav-icon"></span>
        </label>
        {/* <!--menu---> */}
        <ul className="menu">
          <li>
            <a href="#main" className="menu-button link">
              Home
            </a>
          </li>
          <li>
            <a href="#skills" className="menu-button link">
              Skills
            </a>
          </li>
          <li>
            <a href="#portfolio" className="menu-button link">
              Porfolio
            </a>
          </li>
          <li>
            <a href="#services" className="menu-button link">
              Services
            </a>
          </li>
          <li>
            <a href="#contact" className="menu-button link">
              Contact
            </a>
          </li>
        </ul>
      </nav>
      {/* <!--main-section----------------------> */}
      <section id="main">
        {/* <!--text--------------> */}
        <div className="main-text">
          <h1>Hi, I'm a Full-Stack Developer</h1>
          <p>
            Junior full stack developer, prepared to acomplish front-end,
            back-end projects or both.
          </p>
          {/* <!--btns----------> */}
          <div className="main-btns">
            <a href="#contact" className="hire-me-btn link">
              Hire Me
            </a>
          </div>
        </div>
        {/* <!--model--------------> */}
        <div className="model">
          <img src={face} alt="Face" />
        </div>
      </section>
      {/* <!--skills----------------------------> */}
      <section id="skills">
        {/* <!--skills-text-section----> */}
        <div className="skills-text-section">
          {/* <!--heading---------> */}
          <div className="skill-heading" data-aos="fade">
            <span>Skills</span>
            <h2>My Experties</h2>
          </div>
          {/* <!--skills-box-container---> */}
          <div className="s-box-container">
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-html5"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>Hyper Text Markup Language(HTML)</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line html">
                  <span></span>
                </div>
              </div>
            </div>
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-css3-alt"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>Cascading Style Sheets(CSS)</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line css">
                  <span></span>
                </div>
              </div>
            </div>
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-js"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>JavaScript(JS)</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line js">
                  <span></span>
                </div>
              </div>
            </div>
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-react"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>ReactJS</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line react">
                  <span></span>
                </div>
              </div>
            </div>
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-node-js"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>NodeJS</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line node">
                  <span></span>
                </div>
              </div>
            </div>
            {/* <!--skill-box--> */}
            <div className="skill-box" data-aos="fade-up">
              {/* <!--box-icon----> */}
              <div className="s-box-icon">
                <i className="fab fa-php"></i>
              </div>
              {/* <!--box-text----> */}
              <div className="s-box-text">
                <strong>Hypertext Preprocessor(PHP)</strong>
                {/* <!--progress-line----> */}
                <div className="progress-line php">
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PortfolioSlider />
      {/* <!--services-----------------------------------> */}
      <section id="services">
        {/* <!--heading-----> */}
        <strong className="services-heading" data-aos="fade-up">
          Services
        </strong>
        {/* <!--services-box-container-----> */}
        <div className="services-box-container">
          {/* <!--service-box---------> */}
          <div className="service-box" data-aos="fade-up">
            {/* <!--icon--> */}
            <i className="fas fa-code"></i>
            {/* <!--heading---> */}
            <strong>Front-End</strong>
            {/* <!--details--> */}
            <p>
              Frontend is all the results that will work on the browser. Usualy,
              also called "client-side" for this is dedicated to present the
              product (static or dynamic pages, single page applications(SPA).
              It is focused on implementing the structure and design of web
              pages.
            </p>
          </div>
          {/* <!--service-box---------> */}
          <div className="service-box" data-aos="fade-up">
            {/* <!--icon--> */}
            <i className="fas fa-cogs"></i>
            {/* <!--heading---> */}
            <strong>Back-End</strong>
            {/* <!--details--> */}
            <p>
              Backend, also called "server-side", is dedicated to other
              functionalities needed for the dynamic web pages, like data base
              connections (mySQL, MongoDB), REST API web applications, contact
              form message sending.{' '}
            </p>
          </div>
          {/* <!--service-box---------> */}
          <div className="service-box" data-aos="fade-up">
            {/* <!--icon--> */}
            <i className="fas fa-drafting-compass"></i>
            {/* <!--heading---> */}
            <strong>Graphic Designing</strong>
            {/* <!--details--> */}
            <p>
              Graphic design is an art to decode and interpret the client's
              message and vision and present passionate results. For this, I
              work with teams capable of providing the best service to produce a
              complete product.
            </p>
          </div>
        </div>
      </section>
      {/* <!--get-in-touch------------------> */}
      <div id="get-in-touch" data-aos="fade-up">
        <span>Still not conviced for Hiring?</span>
        <a href="#contact" className="link">
          Get In Touch
        </a>
      </div>
      {/* <!--contact---------------> */}
      <section id="contact">
        <div className="contact-image" data-aos="fade-up">
          <img src={facex} alt="Face poly" />
        </div>
        {/* <!--form-----------> */}
        <form className="contact-form" data-aos="fade-up">
          {/* <!--full-name----> */}
          <Form
            name="fullName"
            type="text"
            classType="form_input"
            palceholder="Full Name"
            onChange={handleChange}
            value={fullName}
            error={error}
          />
          {/* <!--email--------> */}
          <Form
            name="email"
            type="text"
            classType="form_input"
            palceholder="Example@domain.com"
            onChange={handleChange}
            value={email}
            error={error}
          />
          {/* <!--write-something--> */}
          <Form
            name="message"
            type="text"
            classType="text_area"
            palceholder="Message"
            onChange={handleChange}
            value={message}
            error={error}
          />
          {/* <!--send-btn----> */}
          <input type="submit" onClick={clickSubmit} value={buttonText} />
        </form>
      </section>
      {/* <!--footer-------------------> */}
      <Footer />
      {CookieContainer()}
    </div>
  );
}

export default App;
