import portfolio from '../../images/portfolio.webp';
import space from '../../images/space.gif';

export default [
  {
    title: 'Keep in touch to check my work',
    description: "Every now and then, I'll update with my latest Worker.",
    urls: portfolio,
    tech: ['react', 'node js', 'express js'],
    alt: 'porfolio demo',
  },
  {
    title: 'Space tourism multi-page website',
    description:
      'The challenge is to build out a multi-page space tourism website and get it looking as close to the design as possible.',
    urls: space,
    tech: ['react', 'react router'],
    alt: 'space tourism website',
    link: '/space-tourism',
    button: 'Check it out',
  },
];
