import CookieConsent, { Cookies } from 'react-cookie-consent';
import { initGA } from '../utils/ga-utils';
import './cookieContainer.css';

const CookieContainer = () => {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  return (
    <CookieConsent
      // disableStyles={true}
      enableDeclineButton
      buttonText="Accept"
      declineButtonText="Reject"
      containerClasses="CookieConsent"
      contentClasses="cookieDesc"
      buttonWrapperClasses="buttonContainer"
      buttonClasses="cookieButton"
      declineButtonClasses="cookieButton"
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
    >
      <span style={{ fontSize: '18px', display: 'block' }}>
        This website uses cookies
      </span>
      <span style={{ fontSize: '13px' }}>
        This site uses necessary cookies for its correct operation and third
        party cookies. Third party cookies help us to improve your user
        experience and some can be used for advertising purposes
        (targeted/advertising cookies). By clicking "Accept" and continuing to
        use this site, you agree to our use of cookies in accordance with our
        cookie policy. If you "Reject" to receives cookies, only essential
        cookies will be used. For more information, read our{' '}
      </span>
      <a href="cookie-policy.html" style={{ fontSize: '13px' }}>
        Cookie Policy
      </a>
      .
    </CookieConsent>
  );
};

export default CookieContainer;
