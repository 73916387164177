import React, { useState, useEffect } from 'react';
import sliderImage from './Slider/sliderData';
// import Dots from './Dots';
import sliderData from './Slider/sliderData';

const len = sliderImage.length - 1;

const PortfolioSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 7000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <section id="portfolio">
      {/* <!--Portfolio-text-section----> */}
      <div className="skills-text-section">
        {/* <!--heading---------> */}
        <div className="skill-heading" data-aos="fade">
          <span>Portfolio</span>
          <h2>My Work</h2>
        </div>
        {/* <!--portfolio-box-container---> */}
        <div className="portfolio-container">
          {sliderData.map((slide, index) => (
            <div
              className={
                index === activeIndex ? 'portfolio_slide active' : 'inactive'
              }
              key={index}
            >
              <div className="portfolio-text">
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
                {slide.tech.map((techItem, i) => (
                  <p key={i} className="tech">
                    #{techItem}
                  </p>
                ))}
                {slide.link && (
                  <button className="siteLink">
                    <a href={slide.link}>{slide.button}</a>
                  </button>
                )}
              </div>
              <div className="portfolio-models">
                <img src={slide.urls} alt={slide.alt} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PortfolioSlider;
